<template>
  <div class="page-container">
    <!--筛选-->
    <div class="filter-container clearFix">
      <list-search-filter :search-filter="lists.searchFilter"
                          @clickSearchFilterBtn="query=>ListMethods().clickSearchFilterBtn(query)">
        <!--  操作  -->
        <div slot="right-container">
          <el-button class="el-button" type="primary" size="small"
                     @click="ListMethods().clickFastResetExperimentTimesBtn()">快速重置实验次数
          </el-button>
          <el-button class="el-button" type="success" size="small"
                     @click="ListMethods().clickAddEntityBtn()">添加实验安排
          </el-button>
        </div>
      </list-search-filter>
    </div>
    <!--列表-->
    <div class="table-container">
      <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="lists.list" v-loading="lists.loading"
                element-loading-text="加载中" fit
                style="width: 100%;" @sort-change="sort=>ListMethods().sortChange(sort)">
        <el-table-column label="学期">
          <template slot-scope="scope">
            <span>{{ scope.row.semestername }}</span>
          </template>
        </el-table-column>
        <el-table-column label="教学班" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.clazzname }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实验课程" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.experimentname }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实验开放时间" align="center" width="280" prop="openStartTimeSort" :sortable="'custom'">
          <template slot-scope="scope">
            <span>{{ scope.row.openstarttime | dateFormat }}-{{ scope.row.openendtime | dateFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实验状态" align="center">
          <template slot-scope="scope">
            <span>{{ enums_experimentStatus[scope.row.experimentstatus] }}</span>

            <!--            <span v-if="scope.row.experimentstatus===0&&scope.row.experimentalpermissions===true" class="status0"><i-->
            <!--                class="statusDot"></i>未开始</span>-->
            <!--            <span v-if="scope.row.experimentstatus===1&&scope.row.experimentalpermissions===true" class="status1"><i-->
            <!--                class="statusDot"></i>进行中</span>-->
            <!--            <span v-if="scope.row.experimentstatus===2&&scope.row.experimentalpermissions===true" class="status2"><i-->
            <!--                class="statusDot"></i>已结束</span>-->
            <!--            <span v-if="scope.row.experimentstatus===3&&scope.row.experimentalpermissions===false" class="status0"><i-->
            <!--                class="statusDot"></i>已关闭</span>-->
            <!--            <span v-if="scope.row.experimentstatus!==3&&scope.row.experimentalpermissions===false" class="status0"><i-->
            <!--                class="statusDot"></i>已关闭</span>-->
          </template>
        </el-table-column>
        <el-table-column label="可重复实验次数" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.repeatabletimes }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实验权限" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="v=>ListMethods().onPermissionChange(scope.row,v)"
                v-model="scope.row.experimentalpermissions"
                active-text="开"
                inactive-text="关">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="260"
                         class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="text" size="mini" round
                       @click="e=>ListMethods().clickResetExperimentTimesBtn(scope.row,scope.$index)">重置实验次数
            </el-button>
            <el-button type="text" size="mini" round
                       @click="e=>ListMethods().clickDeleteBtn(scope.row,scope.$index)">删除
            </el-button>
            <el-button type="text" size="mini" round
                       @click="ListMethods().clickViewBtn(scope.row,scope.$index)">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--列表分页-->
    <div class="pagination-container">
      <el-pagination background @current-change="(number)=>ListMethods().pageChange(number)"
                     :current-page.sync="lists.pages.number" :page-size.sync="lists.pages.size"
                     layout="total,prev, pager, next,sizes,jumper" :total="lists.pages.totalElements"
                     @size-change="(size)=>ListMethods().pageLimitChange(size)"
                     :page-count="lists.pages.totalPages">
      </el-pagination>
    </div>
    <!--弹窗-重置实验次数-->
    <el-dialog
        :close-on-click-modal="false"
        :title="resetTimesInfo.title"
        :visible.sync="resetTimesInfo.dialog"
        width="900px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <!--搜索栏-->
        <div class="search-box flex flex-start" style="margin-bottom: 30px">
          <div class="input-box flex flex-start" style="margin-right: 20px">
            <div style="width: 80px">行政班级：</div>
            <el-select v-model="resetTimesInfo.lists.query.administrationClazzId">
              <el-option v-for="item in resetTimesInfo.administrationClazzFilterArr" :label="item.label"
                         :value="item.value"
                         :key="item.value"></el-option>
            </el-select>
          </div>
          <div class="input-box flex flex-start" style="margin-right: 20px">
            <div style="width: 70px;">账 号：</div>
            <el-input v-model="resetTimesInfo.lists.query.account"></el-input>
          </div>
          <el-button type="primary" @click="ResetTimesMethods().clickSearchBtn()">查找学生</el-button>
        </div>
        <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="resetTimesInfo.lists.list" fit
                  height="600px"
                  v-loading="resetTimesInfo.lists.loading"
                  style="width: 100%;"
                  @selection-change="v=>ResetTimesMethods().onSelected(v)">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column label="账号">
            <template slot-scope="scope">
              <span>{{ scope.row.studentaccount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.studentname }}</span>
            </template>
          </el-table-column>
          <el-table-column label="行政班级" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.gradeName }} - {{ scope.row.administrationClazzName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实验权限" align="center">
            <template slot-scope="scope">
              <el-switch
                  @change="v=>ResetTimesMethods().toggleAuth(scope.row,v)"
                  v-model="scope.row.experimentauth"
                  active-text="开"
                  inactive-text="关">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="重置实验次数" align="center">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click="ResetTimesMethods().clickResetBtn('single',scope.row.id)">
                重置
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--列表分页-->
        <div class="flex flex-between" style="margin-top: 20px;">
          <div style="margin-top: 10px">
            <span style="color:#409eff">已选{{ resetTimesInfo.listSelected.length }}项</span>
          </div>
          <div class="pagination-container">
            <el-pagination background @current-change="(number)=>ResetTimesMethods().pageChange(number)"
                           :current-page.sync="resetTimesInfo.lists.pages.number"
                           :page-size.sync="resetTimesInfo.lists.pages.size"
                           layout="total,prev, pager, next,sizes,jumper"
                           :total="resetTimesInfo.lists.pages.totalElements"
                           :page-sizes="[5,10,20,50,100,200]"
                           @size-change="(size)=>ResetTimesMethods().pageLimitChange(size)"
                           :page-count="resetTimesInfo.lists.pages.totalPages">
            </el-pagination>
          </div>
        </div>

        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button type="primary" v-if="resetTimesInfo.listSelected.length>0" style="margin-top: 20px;"
                     @click="ResetTimesMethods().clickResetBtn('multiple')">批量重置实验次数
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!--弹窗-快速重置实验次数-->
    <el-dialog
        :close-on-click-modal="false"
        title="快速重置实验次数"
        :visible.sync="fastResetTimesInfo.dialog"
        width="1100px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <div style="margin-bottom: 15px;font-size: 14px;color: #999;text-align: center">
          提示：可以重置本校其他老师负责的学生实验次数
        </div>
        <!--搜索栏-->
        <div class="search-box flex flex-center" style="margin-bottom: 30px">
          <div class="input-box flex flex-start" style="margin-right: 20px">
            <div style="width: 70px;">账 号：</div>
            <el-input v-model="fastResetTimesInfo.lists.query.account"></el-input>
          </div>
          <el-button type="primary" @click="FastResetTimesMethods().clickSearchBtn()">查找学生</el-button>
        </div>
        <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="fastResetTimesInfo.lists.list" fit
                  height="600px"
                  v-loading="fastResetTimesInfo.lists.loading"
                  style="width: 100%;">
          <el-table-column label="账号">
            <template slot-scope="scope">
              <span>{{ scope.row.studentAccount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.studentName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="行政班" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.gradeName }} - {{ scope.row.administrationClazzName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="教学班" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.clazzName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实验项目" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.experimentName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否完成实验" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.isFinishExp?'是':"否" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实验分数" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.isFinishExp? numberFormat(scope.row.experimentScore) :"--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="安排次数" align="center" width="80px">
            <template slot-scope="scope">
              <span>{{ scope.row.scheduleNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="剩余次数" align="center" width="80px">
            <template slot-scope="scope">
              <span style="color: red;font-weight: bold">{{ scope.row.experimentNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="重置实验次数" align="center">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click="FastResetTimesMethods().clickResetBtn(scope.row)">
                重置
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * todo 按原型图修改功能
 */
import ListSearchFilter from '@/components/list/listSearchFilter'
import enums from '@/model/EnumsModel'
import elDragDialog from '@/directive/el-drag-dialog'
import {msg_confirm, msg_err, msg_input, msg_success} from '@/utils/ele_component'
import {arrToLVArr, date_format, find_obj_from_arr_by_id, objectToLVArr} from '@/utils/common'
import {mapState} from 'vuex'
import {ClazzModel} from "@/model/exp/ClazzModel";
import {CommonModel} from "@/model/CommonModel";
import {DepartmentModel} from "@/model/exp/DepartmentModel";
import {ExperimentModel} from "@/model/exp/ExperimentModel";
import {EnumsModel} from "@/model/EnumsModel";
import {SemesterModel} from "@/model/exp/SemesterModel";
import {StudentModel} from "@/model/exp/StudentModel";
import {RouterModel} from "@/model/RouterModel";
import {UserModel} from "@/model/exp/UserModel";
import {numberFormat} from "@/filter/filter";

export default {
  name: 'teacherExperiment',
  components: {ListSearchFilter},
  directives: {
    elDragDialog
  },
  // 过滤器
  filters: {
    dateFormat(value) {
      if (value) {
        return date_format(value, "yyyy/MM/dd HH:mm")
      }
      return ""
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    // 校检电话号码
    const validateTimes = (rule, value, callback) => {
      let reg = /^[0-9]+$/
      if (!reg.test(value)) {
        callback(new Error('请输入1-10之间的整数'))
      }
      if (value < 1 || value > 10) {
        callback(new Error('请输入1-10之间的整数'))
      }
      callback()
    }
    return {
      // 外部方法
      date_format: date_format,
      dateNow: new Date().getTime(),
      numberFormat:numberFormat,
      // 枚举
      enums_experimentStatus: EnumsModel.experimentStatus,
      // 列表
      lists: {
        list: [],
        loading: false,
        query: {},
        queryBase: {},
        pages: {
          size: 20
        },
        searchFilter: {
          search: [],
          filter: [
            {
              type: 'select',
              label: '学期',
              key: 'semesterid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '教学班',
              key: 'clazzid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '实验课程',
              key: 'experimentid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '实验状态',
              key: 'experimentalstatus',
              value: '',
              data: objectToLVArr(EnumsModel.experimentStatus, true),
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
          ]
        }
      },
      // 实体详情
      entityInfo: {
        title: "新增实验安排",
        type: "add",
        dialog: false,
        filter: [],
        edit: {
          repeatabletimes: 3,
        },
        // 输入检测
        formRules: {
          'clazzid': {required: true, message: '请选择班级', trigger: 'blur'},
          'semesterid': {required: true, message: '请选择学期', trigger: 'blur'},
          'experimentid': {required: true, message: '请选择实验', trigger: 'blur'},
          'repeatabletimes': {required: true, validator: validateTimes},
          'date': {required: true, message: '请选择实验开放时间', trigger: 'blur'},
        },
      },
      // 重置实验次数列表
      resetTimesInfo: {
        administrationClazzFilterArr: [],
        dialog: false,
        listSelected: [],
        lists: {
          list: [],
          loading: false,
          query: {},
          queryBase: {},
          pages: {
            size: 50
          },
        },
      },
      // 快速重置实验次数列表
      fastResetTimesInfo: {
        administrationClazzFilterArr: [],
        dialog: false,
        listSelected: [],
        lists: {
          list: [],
          loading: false,
          query: {},
          queryBase: {},
          pages: {
            size: 50
          },
        },
      },
    }
  },
  async mounted() {
    // 获取列表
    this.ListMethods().getList(1, this.lists.pages.size, this.lists.query)
    // 初始化筛选
    this.ListMethods().initFilter()
  },
  methods: {
    // 列表Methods
    ListMethods() {
      let $this = this
      return {
        // 获取列表
        async getList(page, size, query) {
          query = Object.assign(query, $this.lists.queryBase)
          $this.lists.loading = true;
          [$this.lists.list, $this.lists.pages] = await ExperimentModel.getList(page, size, query)
          $this.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.lists.pages.size, $this.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.lists.pages.number, size, $this.lists.query)
        },
        // 排序被改变
        async sortChange(sort) {
          if (sort.column.sortable === 'custom') {
            let querySort = $this.lists.query.sort
            querySort = CommonModel.elementTableSort(sort)
            // 时间按开始和结束排序
            querySort = querySort.replace("opentime,desc", "openendtime,desc")
            querySort = querySort.replace("opentime,asc", "openstarttime,asc")
            $this.$set($this.lists.query, "sort", querySort)
            this.getList(1, $this.lists.pages.size, $this.lists.query)
          }
        },
        // 初始化筛选列表
        async initFilter(type) {
          // 获取学期列表
          if (!type || type === 0) {
            let semesterList = await SemesterModel.getList(0, 0, {})
            let generateListFilter0 = CommonModel.generateListFilterOptions('name', 'id', semesterList, true)
            $this.$set($this.lists.searchFilter.filter[0], "data", generateListFilter0[0])
            $this.$set($this.lists.searchFilter.filter[0], "dataObject", generateListFilter0[1])
            $this.$set($this.lists.searchFilter.filter[0], "dataOrigin", semesterList)
          }
          // 获取班级列表
          if (!type || type === 1) {
            let clazzList = (await ClazzModel.getList(1, -1, {}))[0]
            let generateListFilter1 = CommonModel.generateListFilterOptions('clazzName', 'clazzid', clazzList, true)
            $this.$set($this.lists.searchFilter.filter[1], "data", generateListFilter1[0])
            $this.$set($this.lists.searchFilter.filter[1], "dataObject", generateListFilter1[1])
            $this.$set($this.lists.searchFilter.filter[1], "dataOrigin", clazzList)
          }
          // 获取实验列表
          if (!type || type === 2) {
            let experimentList = (await ExperimentModel.getTeacherExperimentList($this.userInfo))
            let generateListFilter1 = CommonModel.generateListFilterOptions('name', 'id', experimentList, true)
            $this.$set($this.lists.searchFilter.filter[2], "data", generateListFilter1[0])
            $this.$set($this.lists.searchFilter.filter[2], "dataObject", generateListFilter1[1])
            $this.$set($this.lists.searchFilter.filter[2], "dataOrigin", experimentList)
          }

          $this.$forceUpdate()
        },
        // 点击搜索按钮
        clickSearchFilterBtn(query) {
          this.getList(1, $this.lists.pages.size, query)
          $this.lists.query = query
        },
        // 点击删除按钮
        async clickDeleteBtn(entity, index) {
          if (entity.experimentstatus !== 3) {
            msg_err("未关闭的实验安排不能被删除!")
            return
          }
          if (await msg_confirm("确认要删除该实验安排吗？")) {
            if (await ExperimentModel.remove([entity.id])) {
              msg_success("删除成功")
              $this.ListMethods().getList($this.lists.pages.number, $this.lists.pages.size, $this.lists.query)
            } else {
              msg_err("删除失败")
            }
          }
        },
        // 点击新增按钮
        clickAddEntityBtn() {
          $this.$router.push({
            name: "teacherExperimentInfo",
            query: {
              type: "add"
            }
          }).then(() => {
            RouterModel.setCurrentNavInfo('/teacher/experimentInfo?type=add', "实验课程安排 / 添加实验课程安排", "", '5', [
              {name: "实验课程安排", path: "/teacher/experiment", index: '5'},
              {name: "添加实验课程安排"}
            ])
          })
        },
        // 点击详情按钮
        clickViewBtn(entity) {
          entity.date = [entity.openstarttime, entity.openendtime]
          localStorage.setItem("experimentScheduleInfo", JSON.stringify(entity))
          $this.$router.push({
            name: "teacherExperimentInfo",
            query: {
              type: "edit",
              id: entity.id
            }
          }).then(() => {
            RouterModel.setCurrentNavInfo('/teacher/experimentInfo?type=edit&id=' + entity.id, "实验课程安排 / 实验课程安排详情", "", '5', [
              {name: "实验课程安排", path: "/teacher/experiment", index: '5'},
              {name: "实验课程安排详情"}
            ])
          })
        },
        // 切换列表某个班级的实验权限
        async onPermissionChange(entity) {
          if (await ExperimentModel.modifyExperimentPermissions(entity)) {
            msg_success("修改成功")
            $this.ListMethods().getList($this.lists.pages.number, $this.lists.pages.size, $this.lists.query)
          }
        },
        // 点击重置实验次数按钮
        async clickResetExperimentTimesBtn(entity) {
          $this.resetTimesInfo.scheduleId = entity.id
          $this.resetTimesInfo.title = entity.clazzname + "  " + entity.experimentname
          $this.$set($this.resetTimesInfo.lists.query, "scheduleId", entity.id)
          $this.$set($this.resetTimesInfo.lists.query, "account", "")
          $this.$set($this.resetTimesInfo.lists.query, "administrationClazzId", [])
          // 获取班级学生列表
          $this.resetTimesInfo.dialog = true
          $this.resetTimesInfo.lists.loading = true
          let listResult = (await ExperimentModel.getResetExperimentTimesList(1, 50, $this.resetTimesInfo.lists.query).catch(res => {
            $this.resetTimesInfo.lists.loading = false
          }))
          let list = listResult[0]
          // 设置行政班筛选数组
          let administrationClazzFilterObject = {};
          for (let i = 0; i < list.length; i++) {
            // 默认所有学生权限开 生成行政班级筛选表
            administrationClazzFilterObject[list[i].administrationClazzId] = list[i].administrationClazzName
          }
          let administrationClazzFilterArr = [];
          for (let i in administrationClazzFilterObject) {
            if (administrationClazzFilterObject.hasOwnProperty(i)) {
              administrationClazzFilterArr.push({
                label: administrationClazzFilterObject[i],
                value: i
              })
            }
          }
          $this.resetTimesInfo.lists.list = list
          $this.resetTimesInfo.lists.pages = listResult[1]
          $this.resetTimesInfo.administrationClazzFilterArr = administrationClazzFilterArr
          $this.resetTimesInfo.lists.loading = false
        },
        // 点击快速重置实验次数按钮
        clickFastResetExperimentTimesBtn() {
          $this.fastResetTimesInfo.dialog = true
        }
      }
    },
    // 重置实验次数弹窗Methods
    ResetTimesMethods() {
      let $this = this;
      return {
        // 选择某些项
        onSelected(v) {
          $this.resetTimesInfo.listSelected = v;
        },
        async getList(page, size, query) {
          $this.resetTimesInfo.lists.loading = true;
          [$this.resetTimesInfo.lists.list, $this.resetTimesInfo.lists.pages] = await ExperimentModel.getResetExperimentTimesList(page, size, query)
          $this.resetTimesInfo.lists.loading = false
        },
        // 点击搜索按钮
        async clickSearchBtn() {
          let query = {
            scheduleId: $this.resetTimesInfo.scheduleId
          }
          if ($this.resetTimesInfo.lists.query.account) {
            query.account = $this.resetTimesInfo.lists.query.account
          }
          if ($this.resetTimesInfo.lists.query.administrationClazzId) {
            query.administrationClazzId = $this.resetTimesInfo.lists.query.administrationClazzId
          }
          // 获取班级学生列表
          this.getList(1, $this.resetTimesInfo.lists.pages.size, query)
        },
        // 点击批量重置实验次数按钮
        async clickResetBtn(type, id) {
          let ids = []
          if (type === 'multiple') { // 批量
            if (await msg_confirm("确定要重置这些学生的实验次数吗?")) {
              ids = $this.resetTimesInfo.listSelected.map(v => {
                return v.id
              })
              if (await ExperimentModel.resetExperimentTimesMultiple(ids, $this.resetTimesInfo.experimentNum)) {
                msg_success("批量重置实验次数成功")
              }
            }
          }
          if (type === 'single') { // 单个
            ids = [id]
            if (await ExperimentModel.resetExperimentTimesMultiple(ids, $this.resetTimesInfo.experimentNum)) {
              msg_success("重置实验次数成功")
            }
          }
        },
        // 切换实验权限
        async toggleAuth(v) {
          if (await ExperimentModel.updateExperiment(v)) {
            msg_success("修改实验权限成功")
          }
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.resetTimesInfo.lists.pages.size, $this.resetTimesInfo.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.resetTimesInfo.lists.pages.number, size, $this.resetTimesInfo.lists.query)
        },
      }
    },
    // 快速重置实验次数弹窗Methods
    FastResetTimesMethods() {
      let $this = this;
      return {
        // 点击搜索按钮
        async clickSearchBtn() {
          let query = {
            schoolId: $this.userInfo.schoolId
          }
          if ($this.fastResetTimesInfo.lists.query.account) {
            query.account = $this.fastResetTimesInfo.lists.query.account
          } else {
            msg_err("请输入账号!");
            return
          }
          $this.fastResetTimesInfo.lists.loading = true;
          let list = await ExperimentModel.getFastResetExperimentTimesList(query)
          for (let i = 0; i < list.length; i++) {
            list[i]["experimentName"] = $this.lists.searchFilter.filter[2]["dataObject"][list[i]["experimentId"]]
            if (!list[i]["experimentName"]) {
              list[i]["experimentName"] = "未知实验"
            }
          }
          $this.fastResetTimesInfo.lists.list = list
          $this.fastResetTimesInfo.lists.loading = false

        },
        // 点击重置按钮
        clickResetBtn(entity) {
          $this.ResetTimesMethods().clickResetBtn("single",entity.studentExperimentId)
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
// 实验状态相关
.statusDot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  margin-right: 5px;
  vertical-align: middle;
}

.status0 .statusDot {
  background-color: #bfbfbf;
}

.status1 .statusDot {
  background-color: #0e77d1;
}

.status2 .statusDot {
  background-color: #00a854;
}
</style>
